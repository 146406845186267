<template>
  <div>
    <AccountsTable ref="accTable"
        :noHitText="$t('tra.ano')" />
  </div>
</template>

<script>
    // <b-row fluid class="mx-0 mb-2 mb-sm-3 justify-content-end">
    //   <b-button type="button" variant="primary" id="traRef" @click="getAccData()" class="mx-0">
    //     <Icon type="f" /> {{ $t('btn.f') }}
    //   </b-button>
    // </b-row>
// import Icon from '@/components/Icon';
import AccountsTable from '@/components/transactions/AccountsTable';

export default {
  methods: {
    // getAccData() {
    //   this.$refs['accTable'].getAccData();
    // },
  },
  components: {
    // Icon,
    AccountsTable,
  }
}
</script>
