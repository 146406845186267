<template>
  <div>
    <b-table striped hover :fixed="isXs"
        class="mb-2 mb-sm-3"
        :items="items" :fields="fields"
        no-local-sorting
        v-if="items.length">
      <template #cell(state)>
        <span v-b-tooltip="{trigger: istouch()?'click':'hover', delay: 10}" :title="$t('tra.a'+icon)"><Icon :type="icon" :isButton="false" /></span>
      </template>
      <template #cell(currency)>
        <span>{{ currency }}</span>
      </template>
      <template #cell(amount)="data">
        <span>{{ getMoney(data.item.amount, data.item.currency) }}</span>
      </template>
      <template #cell(time)>
        <span>{{ getDate(updateTime) }}</span>
      </template>
      <template #cell(uuid4)="data">
        <b-btn-group text="">
          <ButtonSet :isDd="isXs" :useCols="false" :id="items.indexOf(data.item)" :item="data.item" suffix="accTab"
              :beforeShow="true" beforeVariant="primary" @beforeClick="getAccData" beforeSuffix="Ref" beforeIcon="f"
              :afterShow="isUser" afterVariant="primary" @afterClick="onChaExecution(data.item.uuid4, data.item.currency)" afterSuffix="Cha" afterIcon="tra">
            <template #before>
              {{ $t('btn.f') }}
            </template>
            <template #after>
              {{ $t('tra.hcha') }}
            </template>
          </ButtonSet>
        </b-btn-group>
      </template>
    </b-table>
    <b-card v-else>
      <b-link v-b-modal.accCreate><Icon type="add" />{{ noHitText }}</b-link>
    </b-card>
    <ModalYesNoGeneric id="accCreate" :title="$t('tra.acrt')"
        :btnTextReset="$t('btn.n')" btnIconReset="x"
        :btnTextSubmit="$t('btn.y')" btnIconSubmit="acc" btnVariantSubmit="secondary"
        @reset="items=[]" @submit="onAccCreation()">
      <p>{{ $t('tra.acri') }}</p>
      <p>{{ $t('tra.acrq', [currency]) }}</p>
    </ModalYesNoGeneric>
    <ModalCharge v-if="isUser" id="chaCreate" :chaUuid="chaUuid" :chaCurrency="chaCurrency" />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import ButtonSet from '@/components/transactions/ButtonSet';
import ModalYesNoGeneric from '@/components/ModalYesNoGeneric';
import { utcToZonedTime } from 'date-fns-tz';
import { REST } from '@/components/RestCall';

export default {
  data() {
    return {
      items: [],
      currency: 'EUR',
      updateTime: null,
      outdated: false,
      chaUuid: null,
      chaCurrency: null,
    };
  },
  created() {
    this.getAccData();
  },
  computed: {
    fields() {
      return [
        { key: 'state', label: '', class: 'px-2 px-sm-3', thStyle: 'width: 25px;' },
        { key: 'currency', label: this.$t('tab.r'), class: 'px-1 px-sm-3', thStyle: 'width: 20%;' },
        { key: 'amount', label: this.$t('tab.u'), class: 'px-1 px-sm-3', thStyle: 'width: 20%;' },
        { key: 'time', label: this.$t('tab.d'), class: 'px-1 px-sm-3', thStyle: 'width 60%;' },
        { key: 'uuid4', label: this.isXs?'':this.$t('tab.a'), class: 'pl-2 pl-sm-3 pr-0', thStyle: 'width: 35px;' },
      ];
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
    icon() {
      return this.outdated ? 'tx' : 't';
    }
  },
  methods: {
    triggerIconUpdate() {
      this.updateTime = new Date().getTime();
      this.outdated = false;
      window.setTimeout(() => { this.outdated = true; }, 120000);
    },
    getDate(d) {
      if (d == null) return '';
      return this.$d(utcToZonedTime(d, this.tz), 'as');
    },
    getMoney(a, v) {
      return this.$n(a/100, { key: 'c', currency: v });
    },
    getAccData() {
      this.triggerIconUpdate();
      REST.get('/' + (this.isUser ? 'users' : 'customers') + '/' + this.$store.getters.uuid + '/accounts'
        ).then(resp => {
          this.items = resp.d.data;
        }).catch(e => {
          this.items = [];
          // catch 404 warning: user may not have added acc yet
          if (e.code != 404) {
            this.$store.commit('showWarn',e.message);
          }
          this.$emit('loaded', { hasContent: false, hasMore: false });
        });
    },
    onAccCreation() {
      REST.post('/accounts', { loginUuid4: this.$store.getters.uuid, currency: this.currency }
        ).then(resp => {
          this.$store.commit('showSuccess', resp.d.message);
          this.getAccData();
        }).catch(e => {
          this.$store.commit('showWarn', e.message);
        });
    },
    onChaExecution(uuidString, currencyString) {
      this.chaUuid = uuidString;
      this.chaCurrency = currencyString;
      this.$nextTick(() => {
        this.$bvModal.show('chaCreate');
      })
    },
    istouch() {
      return (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
    },
  },
  props: {
    noHitText: {
      type: String,
      default: 'No Anures account found. Add a new one!',
    },
  },
  components: {
    Icon,
    ButtonSet,
    ModalYesNoGeneric,
    ModalCharge: () => import('@/components/transactions/ModalCharge'),
  },
}
</script>
